export default [
  {
    path: '/productPurchase',
    name: 'productPurchase',
    component: () => import(`./productPurchase/index.vue`),
    meta: {
      title: '商品采购'
    }
  },
  {
    path: '/middleman',
    name: 'middleman',
    component: () => import(`./userManagement/middleman/index.vue`),
    meta: {
      title: '中间商管理'
    }
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import(`./userManagement/supplier/index.vue`),
    meta: {
      title: '供应商管理'
    }
  },
  {
    path: '/classification',
    name: 'classification',
    component: () => import(`./userManagement/classification/index.vue`),
    meta: {
      title: '商品分类管理'
    }
  },

  {
    path: '/productList',
    name: 'productList',
    component: () => import(`./goodsManagement/productList/index.vue`),
    meta: {
      title: '商品信息管理'
    }
  },

  {
    path: '/brandList',
    name: 'brandList',
    component: () => import(`./goodsManagement/brandList/index.vue`),
    meta: {
      title: '商品品牌'
    }
  },

  {
    path: '/groupList',
    name: 'groupList',
    component: () => import(`./goodsManagement/groupList/index.vue`),
    meta: {
      title: '商品仓库归属'
    }
  },

  {
    path: '/clazzList',
    name: 'clazzList',
    component: () => import(`./goodsManagement/clazzList/index.vue`),
    meta: {
      title: '商品分类管理'
    }
  },

  {
    path: '/updownManage',
    name: 'updownManage',
    component: () => import(`./goodsManagement/updownManage/index.vue`),
    meta: {
      title: '入库商品管理'
    }
  },

  {
    path: '/orderList',
    name: 'orderList',
    component: () => import(`./orderManagement/orderList/index.vue`),
    meta: {
      title: '全部订单明细'
    }
  },

  {
    path: '/noSendOrderList',
    name: 'noSendOrderList',
    component: () => import(`./orderManagement/orderList/noSendOrderList.vue`),
    meta: {
      title: '未发货订单管理'
    }
  },

  {
    path: '/haveSendOrderList',
    name: 'haveSendOrderList',
    component: () =>
      import(`./orderManagement/orderList/haveSendOrderList.vue`),
    meta: {
      title: '已发货订单管理'
    }
  },

  {
    path: '/orderQuery',
    name: 'orderQuery',
    component: () => import(`./orderManagement/orderQuery/index.vue`),
    meta: {
      title: '未发货商品查询'
    }
  },

  {
    path: '/orderDownLoad',
    name: 'orderDownLoad',
    component: () => import(`./orderManagement/orderDownLoad/index.vue`),
    meta: {
      title: '发货单下载'
    }
  },

  {
    path: '/preOrderList',
    name: 'preOrderList',
    component: () => import(`./orderManagement/preOrderList/index.vue`),
    meta: {
      title: '预定订单'
    }
  },

  {
    path: '/afterOrderList',
    name: 'afterOrderList',
    component: () => import(`./orderManagement/afterOrderList/index.vue`),
    meta: {
      title: '售后订单'
    }
  },

  {
    path: '/groupConfig',
    name: 'groupConfig',
    component: () => import(`./supplyManagement/groupConfig/index.vue`),
    meta: {
      title: '分组优惠配置'
    }
  },

  {
    path: '/operatorsConfig',
    name: 'operatorsConfig',
    component: () => import(`./supplyManagement/operatorsConfig/index.vue`),
    meta: {
      title: '商品优惠配置'
    }
  },
  {
    path: '/supplyAreaConfig',
    name: 'supplyAreaConfig',
    component: () => import(`./supplyManagement/supplyAreaConfig/index.vue`),
    meta: {
      title: '供货区域配置'
    }
  },
  {
    path: '/offlineConfig',
    name: 'offlineConfig',
    component: () => import(`./supplyManagement/offlineConfig/index.vue`),
    meta: {
      title: '线下收款配置'
    }
  },

  {
    path: '/relateMiddleMan',
    name: 'relateMiddleMan',
    component: () => import(`./supplyManagement/relateMiddleMan/index.vue`),
    meta: {
      title: '关联中间商'
    }
  },

  {
    path: '/commodityWarehousing',
    name: 'commodityWarehousing',
    component: () =>
      import(`./supplyManagement/commodityWarehousing/index.vue`),
    meta: {
      title: '商品入库'
    }
  },
  {
    path: '/addStorage',
    name: 'addStorage',
    component: () =>
      import(`./supplyManagement/commodityWarehousing/addStorage.vue`),
    meta: {
      title: '新增入库'
    }
  },

  {
    path: '/outboundGoods',
    name: 'outboundGoods',
    component: () => import(`./supplyManagement/outboundGoods/index.vue`),
    meta: {
      title: '商品出库'
    }
  },
  {
    path: '/addOutbound',
    name: 'addOutbound',
    component: () => import(`./supplyManagement/outboundGoods/addOutbound.vue`),
    meta: {
      title: '新增出库'
    }
  },
  {
    path: '/icstockbillEntry',
    name: 'icstockbillEntry',
    component: () => import(`./supplyManagement/icstockbillEntry/index.vue`),
    meta: {
      title: '出入库明细'
    }
  },
  {
    path: '/goodsStock',
    name: 'goodsStock',
    component: () => import(`./supplyManagement/goodsStock/index.vue`),
    meta: {
      title: '商品库存信息'
    }
  },
  {
    path: '/goodsInventory',
    name: 'goodsInventory',
    component: () => import(`./supplyManagement/goodsInventory/index.vue`),
    meta: {
      title: '商品盘点'
    }
  },

  {
    path: '/goodsStatisyics',
    name: 'goodsStatisyics',
    component: () => import(`./supplyManagement/goodsStatisyics/index.vue`),
    meta: {
      title: '商品统计信息'
    }
  },

  {
    path: '/ruleConfig',
    name: 'ruleConfig',
    component: () => import(`./purchaseManagement/ruleConfig/index.vue`),
    meta: {
      title: '采购规则设置'
    }
  },
  {
    path: '/supplierMaintenance',
    name: 'supplierMaintenance',
    component: () =>
      import(`./purchaseManagement/supplierMaintenance/index.vue`),
    meta: {
      title: '供应商关系维护'
    }
  },
  {
    path: '/supplierGoodsSelect',
    name: 'supplierGoodsSelect',
    component: () =>
      import(`./purchaseManagement/supplierGoodsSelect/index.vue`),
    meta: {
      title: '供应商商品入库'
    }
  },
  {
    path: '/goodsUpMaintenance',
    name: 'goodsUpMaintenance',
    component: () =>
      import(
        `./purchaseManagement/purchaseGoodsMaintenance/goodsUpMaintenance/index.vue`
      ),
    meta: {
      title: '已上架商品管理'
    }
  },
  {
    path: '/goodsDownMaintenance',
    name: 'goodsDownMaintenance',
    component: () =>
      import(
        `./purchaseManagement/purchaseGoodsMaintenance/goodsDownMaintenance/index.vue`
      ),
    meta: {
      title: '未上架商品管理'
    }
  },
  {
    path: '/storeProcurementOrders',
    name: 'storeProcurementOrders',
    component: () =>
      import(`./purchaseManagement/storeProcurementOrders/index.vue`),
    meta: {
      title: '门店订单明细'
    }
  },
  {
    path: '/storeProcurementOrdersMerge',
    name: 'storeProcurementOrdersMerge',
    component: () =>
      import(`./purchaseManagement/storeProcurementOrdersMerge/index.vue`),
    meta: {
      title: '门店订单合并下单'
    }
  },
  {
    path: '/storeProcurementOrdersSend',
    name: 'storeProcurementOrdersSend',
    component: () =>
      import(`./purchaseManagement/storeProcurementOrdersSend/index.vue`),
    meta: {
      title: '门店订单发货'
    }
  },
  {
    path: '/storeProcurementOrdersManage',
    name: 'storeProcurementOrdersManage',
    component: () =>
      import(`./purchaseManagement/storeProcurementOrdersManage/index.vue`),
    meta: {
      title: '已发货订单管理'
    }
  },
  {
    path: '/supplypurchase',
    name: 'supplypurchase',
    component: () => import(`./purchaseManagement/supplypurchase/index.vue`),
    meta: {
      title: '供应商采购'
    }
  },

  {
    path: '/roleManage',
    name: 'roleManage',
    component: () => import(`./systemManagement/roleManage/index.vue`),
    meta: {
      title: '角色管理'
    }
  },
  {
    path: '/memberManage',
    name: 'memberManage',
    component: () => import(`./systemManagement/memberManage/index.vue`),
    meta: {
      title: '人员管理'
    }
  },
  {
    path: '/deliveryTimeManage',
    name: 'deliveryTimeManage',
    component: () => import(`./systemManagement/deliveryTimeManage/index.vue`),
    meta: {
      title: '配送时间管理'
    }
  },
  {
    path: '/storeInfoManage',
    name: 'storeInfoManage',
    component: () => import(`./storeManagement/storeInfoManage/index.vue`),
    meta: {
      title: '门店信息管理'
    }
  },
  {
    path: '/groupManage',
    name: 'groupManage',
    component: () => import(`./storeManagement/groupManage/index.vue`),
    meta: {
      title: '门店分组管理'
    }
  },
  {
    path: '/auditManage',
    name: 'auditManage',
    component: () => import(`./storeManagement/auditManage/index.vue`),
    meta: {
      title: '审批管理'
    }
  },
  {
    path: '/carManage',
    name: 'carManage',
    component: () => import(`./logisticsManagement/carManage/index.vue`),
    meta: {
      title: '车辆管理'
    }
  },
  {
    path: '/orderPurchaseReport',
    name: 'orderPurchaseReport',
    component: () => import(`./reportManagement/orderPurchaseReport/index.vue`),
    meta: {
      title: '采购订单统计表'
    }
  },
  {
    path: '/orderRefundReport',
    name: 'orderRefundReport',
    component: () => import(`./reportManagement/orderRefundReport/index.vue`),
    meta: {
      title: '退款订单统计表'
    }
  },
  {
    path: '/turnover',
    name: 'turnover',
    component: () => import(`./productPurchase/turnOver/index.vue`),
    meta: {
      title: ''
    }
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import(`./notification/index.vue`),
    meta: {
      title: '通知信息'
    }
  }
]
